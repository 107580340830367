@import "https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap";
body {
  --body-font: "Montserrat", sans-serif;
  font-family: var(--body-font);
  text-align: center;
  --item-size: 1em;
  font-size: var(--item-size);
  --accent-color: #fff;
  --border-color: #ccc;
  --body-color: #e0e0e0;
  --background-color: #121212;
  --icon-color-filter: initial;
  --error-color: #ff4d4d;
  --success-color: #4caf50;
  color: var(--body-color);
  --button-background-color: rgba(var(--background-color), .5);
  --button-hover-background-color: var(--accent-color);
  --modal-background-color: #00000080;
  --input-background-color: var(--background-color);
  --input-border-color: var(--border-color);
  --table-header-background: #ffffff1a;
  --table-row-odd-background: #0003;
  --table-row-even-background: #0000001a;
  --table-border-color: #fff3;
  background: linear-gradient(#371901, #080400) no-repeat fixed;
  flex-direction: column;
  width: 100dvw;
  min-width: 100dvw;
  height: 100dvh;
  min-height: 100dvh;
  margin: 0;
  padding: 0;
  display: flex;
  overflow-x: hidden;
}

.circular-display {
  box-sizing: border-box;
  clip-path: circle(50%);
  --item-size: 1rem;
  border: 1px solid #000;
  border-radius: 50%;
  justify-content: center;
  display: flex;
  position: relative;
  overflow: hidden;
}

.circular-display p {
  margin: .5em;
}

.circular-display h2 {
  margin: .6em;
}

styled-button {
  --font-size: calc(var(--item-size) * .9);
  --button-font: var(--body-font);
  --border: 1.5px solid #e0e0e033;
}

hr {
  color: var(--body-color);
  border-style: solid;
  width: 90%;
}

a {
  color: var(--body-color);
}

input, select, textarea, button {
  font-family: inherit;
  font-size: calc(var(--item-size) * .9);
  border-radius: 4px;
  padding: 8px;
  color: var(--body-color) !important;
  background-color: var(--input-background-color) !important;
  border: 1px solid var(--input-border-color) !important;
}

select {
  cursor: pointer;
}

.main-content {
  width: 50%;
  margin-left: 25%;
  margin-right: 25%;
}

h1 {
  font-size: calc(var(--item-size) * 1.5);
}

.title-header {
  margin-top: 0;
  padding-top: 0;
}

.left-content p, ul, li, pre, code {
  text-align: left;
}

.last-move {
  color: #a0a0a0;
  font-size: 90%;
}

chess-game-review {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media screen and (width >= 800px) {
  body {
    --item-size: 1.2em;
  }
}

@media screen and (width >= 1200px) {
  body {
    --item-size: 1.3em;
  }
}

@media screen and (width >= 1500px) {
  body {
    --item-size: 1.4em;
  }
}

@media screen and (width >= 1900px) {
  body {
    --item-size: 1.5em;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fade-out {
  animation: .15s forwards fadeOut;
}

.fade-in {
  animation: .15s forwards fadeIn;
}
/*# sourceMappingURL=app.326ed68a.css.map */
