@import url('https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body {
    min-width: 100dvw;
    min-height: 100dvh;
    width: 100dvw;
    height: 100dvh;
    
    overflow-x: hidden;
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: column;

    /* Attributes common to all themes */
    --body-font: "Montserrat", sans-serif;
    font-family: var(--body-font);
    text-align: center;

    --item-size: 1em;
    font-size: var(--item-size);

    --accent-color: #FFF;
    --border-color: #cccccc;

    /* Light theme specific attributes */
    --body-color: #E0E0E0;
    --background-color: #121212;
    background: linear-gradient(to bottom, #371901, #080400);
    background-repeat: no-repeat;
    background-attachment: fixed; 

    --icon-color-filter: initial;

    --error-color: #FF4D4D;
    --success-color: #4caf50;

    color: var(--body-color);
    
    --button-background-color: rgba(var(--background-color), 0.5);
    --button-hover-background-color: var(--accent-color);

    --modal-background-color: rgba(0, 0, 0, 0.5);

    --input-background-color: var(--background-color);
    --input-border-color: var(--border-color);

    /* New Table-Specific Variables */
    --table-header-background: rgba(255, 255, 255, 0.1); /* Light header background */
    --table-row-odd-background: rgba(0, 0, 0, 0.2);     /* Darker for odd rows */
    --table-row-even-background: rgba(0, 0, 0, 0.1);    /* Slightly lighter for even rows */
    --table-border-color: rgba(255, 255, 255, 0.2);     /* Subtle table border */
}


/* For smartwatch */
.circular-display {
    border-radius: 50%;         /* Enforce circular shape */
    overflow: hidden;           /* Hide anything outside the circle */
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: center;
    clip-path: circle(50% at 50% 50%); /* Enforce the circular clip */
    border: 1px solid black;
    --item-size: 1rem;
}

.circular-display p {
    margin: 0.5em;
}

.circular-display h2 {
    margin: 0.6em;
}

styled-button {
    --font-size: calc(var(--item-size) * 0.9);
    --button-font: var(--body-font);
    --border: 1.5px solid rgba(224, 224, 224, 0.2); /* Lighten the border */
}

hr {
    width: 90%;
    color: var(--body-color);
    border-style: solid;
}

a {
    color: var(--body-color);
}

input,
select,
textarea,
button {
    color: var(--body-color) !important;
    /* Light gray for text */
    background-color: var(--input-background-color) !important;
    /* Dark background */
    border: 1px solid var(--input-border-color) !important;
    /* Styled border */
    padding: 8px;
    border-radius: 4px;
    font-family: inherit;
    /* Consistent font styling */
    font-size: calc(var(--item-size) * 0.9);
}

select {
    cursor: pointer;
}

/*.content-container {
    padding-top: 5px;
    padding-bottom: 5px;

    position: relative;
    background-color: var(--background-color);
    border-radius: 0 20px 0 0;
    width: 97%;
    left: 1.5%;

    flex-grow: 1;
}*/

.main-content {
    width: 50%;
    margin-left: 25%;
    margin-right: 25%;
}

h1 {
    font-size: calc(var(--item-size) * 1.5);
}

.title-header {
    margin-top: 0;
    padding-top: 0;
}

.left-content p,
ul,
li,
pre,
code {
    text-align: left;
}

.last-move {
    font-size: 90%;
    color: #A0A0A0; /* Subtle gray */
}

chess-game-review {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media screen and (min-width: 800px) {
    body {
        --item-size: 1.2em;
    }
}

@media screen and (min-width: 1200px) {
    body {
        --item-size: 1.3em;
    }
}


@media screen and (min-width: 1500px) {
    body {
        --item-size: 1.4em;
    }
}

@media screen and (min-width: 1900px) {
    body {
        --item-size: 1.5em;
    }
}

/* Animations */
@keyframes fadeOut {
    from {
        opacity: 1;
    }
    to {
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.fade-out {
    animation: fadeOut 0.15s forwards;
}

.fade-in {
    animation: fadeIn 0.15s forwards;
}
